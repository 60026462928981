import { default as company1fBdzDITjxMeta } from "/vercel/path0/pages/company.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as industryGA0FmQ34K6Meta } from "/vercel/path0/pages/industry.vue?macro=true";
import { default as servicesoiqz5GGY44Meta } from "/vercel/path0/pages/services.vue?macro=true";
import { default as technologiesIPxZme1R8lMeta } from "/vercel/path0/pages/technologies.vue?macro=true";
export default [
  {
    name: company1fBdzDITjxMeta?.name ?? "company",
    path: company1fBdzDITjxMeta?.path ?? "/company",
    meta: company1fBdzDITjxMeta || {},
    alias: company1fBdzDITjxMeta?.alias || [],
    redirect: company1fBdzDITjxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: industryGA0FmQ34K6Meta?.name ?? "industry",
    path: industryGA0FmQ34K6Meta?.path ?? "/industry",
    meta: industryGA0FmQ34K6Meta || {},
    alias: industryGA0FmQ34K6Meta?.alias || [],
    redirect: industryGA0FmQ34K6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/industry.vue").then(m => m.default || m)
  },
  {
    name: servicesoiqz5GGY44Meta?.name ?? "services",
    path: servicesoiqz5GGY44Meta?.path ?? "/services",
    meta: servicesoiqz5GGY44Meta || {},
    alias: servicesoiqz5GGY44Meta?.alias || [],
    redirect: servicesoiqz5GGY44Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/services.vue").then(m => m.default || m)
  },
  {
    name: technologiesIPxZme1R8lMeta?.name ?? "technologies",
    path: technologiesIPxZme1R8lMeta?.path ?? "/technologies",
    meta: technologiesIPxZme1R8lMeta || {},
    alias: technologiesIPxZme1R8lMeta?.alias || [],
    redirect: technologiesIPxZme1R8lMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/technologies.vue").then(m => m.default || m)
  }
]